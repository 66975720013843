// 获取列表
const getListURL = `${API_CONFIG.baseURL}smOwnerStoreRoomAction!list.action`
// 导出列表
const exportListURL = `${API_CONFIG.baseURL}smOwnerStoreRoomAction!exportToExcel.action`
// 获取库房地址列表
const getStoreroomListURL = `${API_CONFIG.baseURL}serverCodewordAction!getStoreRooms.action`
// 批量操作
const batchURL = `${API_CONFIG.baseURL}smUserAccountAction!batchUpdateRechargeCardStatus.action`
// 库房导入接口
const uploadUrl = `${API_CONFIG.butlerBaseURL}asset/import`
// 获取用户房产信息列表
const getStoreRoomUserListUrl = `${API_CONFIG.baseURL}serverCodewordAction!getStoreRoomUserList.action`
// // 库房管理保存接口
// const storeRoomUserSaveUrl = `${API_CONFIG.baseURL}smOwnerStoreRoomAction!save.action`
// // 库房管理更新接口
// const storeRoomUserUploadUrl = `${API_CONFIG.baseURL}smOwnerStoreRoomAction!update.action`
// // 获取库房详情
// const getStoreRoomDetailUrl = `${API_CONFIG.baseURL}smOwnerStoreRoomAction!queryOne.action`
// 库房管理更新接口
const storeRoomUserUploadUrl = `${API_CONFIG.butlerBaseURL}editOwnerStoreRoom`
// 获取库房详情
const getStoreRoomDetailUrl = `${API_CONFIG.butlerBaseURL}ownerStoreRoomById`
export {
  getListURL,
  exportListURL,
  getStoreroomListURL,
  batchURL,
  uploadUrl,
  getStoreRoomUserListUrl,
  getStoreRoomDetailUrl,
  storeRoomUserUploadUrl
}
