var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "import-container" },
    [
      _c(
        "div",
        { staticClass: "header" },
        [
          _c("v-button", {
            attrs: { text: "返回" },
            on: { click: _vm.previous },
          }),
        ],
        1
      ),
      _c(
        "import-template",
        { attrs: { uploadUrl: _vm.uploadUrl, downloadUrl: _vm.downloadUrl } },
        [
          _c("div", { attrs: { slot: "remark" }, slot: "remark" }, [
            _c("p", [
              _vm._v("1、用户手机号、房号、库房在后台中已存在，才可录入；"),
            ]),
            _c("p", [
              _vm._v("2、模板中红色字体部分为必填项，灰色部分为非必填项；"),
            ]),
            _c("p", [_vm._v("3、最大支持10MB的excel文件，超过请拆分导入。")]),
            _c("div", [
              _c(
                "label",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "90px",
                    "font-weight": "700",
                  },
                },
                [_vm._v("字段说明：")]
              ),
              _c("p", [
                _vm._v(
                  "1、库房所属项目：必填，必须是项目信息表中已有的项目名称；"
                ),
              ]),
              _c("p", [_vm._v("2、库房所属苑：非必填；")]),
              _c("p", [_vm._v("3、库房所属幢：必填；")]),
              _c("p", [_vm._v("4、库房所属单元：非必填；")]),
              _c("p", [_vm._v("5、库房所属室：非必填；")]),
              _c("p", [_vm._v("6、业主手机号：必填；")]),
              _c("p", [
                _vm._v(
                  "7、业主所属项目：必填，必须是项目信息表中已有的项目名称；"
                ),
              ]),
              _c("p", [_vm._v("8、苑：非必填；")]),
              _c("p", [_vm._v("9、幢：必填；")]),
              _c("p", [_vm._v("10、单元：非必填；")]),
              _c("p", [_vm._v("11、室：非必填；")]),
              _c("p", [_vm._v("12、状态：必填，单选：正常、关闭")]),
              _c("p", [_vm._v("13、开始时间: 非必填, 格式：YY-MM-DD")]),
              _c("p", [_vm._v("14、结束时间: 非必填, 格式：YY-MM-DD")]),
              _c("p", [_vm._v("15、备注: 非必填, 限制200个字符")]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }